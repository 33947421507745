import "../scss/octopus.scss";
import "../scss/tooltip.scss";
import "ol/ol.css";
import fileDownload from "js-file-download";
import { customXhr } from "./customFeatureLoader";
import ClipboardJS from "clipboard";

import Vue from "vue/dist/vue";
import { el } from "redom";
import { stringify, parse as parseQueryString } from "query-string";

import { Map, Overlay, View } from "ol";
import { ScaleLine } from "ol/control";
import { platformModifierKeyOnly } from "ol/events/condition";
import { GeoJSON } from "ol/format";
import { DragBox } from "ol/interaction";
import { Group, Tile, Vector as VectorLayer } from "ol/layer";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { Cluster, TileWMS, Vector as VectorSource } from "ol/source";
import { DEFAULT_WMS_VERSION } from "ol/source/common";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";

import "sidebar-v2/css/ol3-sidebar.css";
import Sidebar from "sidebar-v2/js/ol5-sidebar";
import "vue-query-builder/dist/VueQueryBuilder.css";
import VueQueryBuilder from "vue-query-builder";

import SidebarPane from "./SidebarPane.vue";
import IconBase from "./icons/IconBase.vue";
import IconChevronDown from "./icons/IconChevronDown.vue";
import IconChevronUp from "./icons/IconChevronUp.vue";
import LayerSwitcher from "./LayerSwitcher.vue";

import { validation } from "./validations";
import BASE_MAPS_GROUP from "./bases";
import {
  CGI_URL,
  INITIAL_CLUSTERING_ENABLED,
  EXTRA_WMS_SOURCE_PARAMS,
  INITIAL_CLUSTER_DISTANCE,
  LAYER_HIERARCHY,
  PROJECTION,
  QUERY_CONFIG,
  WFS_FEATURE_COUNT,
  WFS_URL,
  WMS_URL,
  formatFieldName,
  formatFieldValue,
  getQueryRules,
  INTENDED_USES,
} from "./constants";

new ClipboardJS(".clipboard"); // eslint-disable-line no-new

// https://openlayers.org/en/latest/examples/cluster.html
const styleCache = {};

const collectionName = parseQueryString(window.location.search).collection;

// This is a confusing function because the return values are all over the place. They can be:
// - null
// - Tile layer
// - Vector layer
// - Group of layers
// But this seems to be the main map builder - refactors will affect the whole map app so do with great caution!
function buildLayers(layer, crumbs = []) {
  const params = {
    title: layer.title,
    colour: layer.colour,
  };
  if (!layer.layers) {
    return null;
  }
  if (typeof layer.layers === "string") {
    let source = new TileWMS({
      projection: PROJECTION,
      url: WMS_URL,
      ...EXTRA_WMS_SOURCE_PARAMS,
      params: {
        layers: layer.layers,
        format: "image/png",
        transparent: true,
      },
    });
    let LayerType = Tile;
    if (layer.cluster && INITIAL_CLUSTERING_ENABLED) {
      LayerType = VectorLayer;
      // create vector layer for clustering
      const wfsParams = {
        version: DEFAULT_WMS_VERSION,
        service: "WFS",
        request: "GetFeature",
        outputFormat: "application/json",
        typeNames: layer.layers,
        srsName: PROJECTION,
      };
      const vectorUrl = (extent) => {
        const cqlFilter = wmsSource.getParams().cql_filter;
        const extraParams = cqlFilter
          ? {
              cql_filter: cqlFilter,
            }
          : {
              bbox: extent.concat([PROJECTION]).join(","),
            };
        const query = stringify({
          ...wfsParams,
          ...extraParams,
        });
        return `${WFS_URL}?${query}`;
      };
      // const formatType = new GeoJSON();
      const wmsSource = source; // stash for getGetFeatureUrl later
      source = new VectorSource({
        format: new GeoJSON(),
        loader: customXhr(vectorUrl),
        projection: PROJECTION,
        strategy: bboxStrategy,
        ...EXTRA_WMS_SOURCE_PARAMS,
      });

      source = new Cluster({
        distance: INITIAL_CLUSTER_DISTANCE,
        geometryFunction: (feature) => {
          const geom = feature.getGeometry();
          return geom.getType() === "Point"
            ? geom
            : geom.getPolygon(0).getInteriorPoint();
        },
        source,
      });
      // pretend it's a TileWMS source...
      // TBC: how to actually take cluster radius into account for click?
      source.getGetFeatureInfoUrl = (...args) =>
        wmsSource.getGetFeatureInfoUrl(...args);
      params.wmsSource = wmsSource; // stash for WMS CQL
      // cluster styling for VectorLayer:
      params.style = (feature, resolution) => {
        const size = feature.get("features").length;
        if (layer.polygonal && resolution < 1500) {
          const firstGeometry = feature.get("features")[0].getGeometry();
          if (firstGeometry.getType() === "MultiPolygon") {
            return new Style({
              geometry: firstGeometry.getPolygon(0),
              stroke: new Stroke({
                color: "#fff",
              }),
              fill: new Fill({
                color: layer.colour || "rgba(51, 51, 51, 0.5)",
              }),
              text: new Text({
                text: size > 1 ? size.toString() : "",
                fill: new Fill({
                  color: "#fff",
                }),
              }),
            });
          }
        }
        const styleKey = `${size}-${layer.colour}`;
        let style = styleCache[styleKey];
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: Math.min(7 + size / 4, 20),
              stroke: new Stroke({
                color: "#fff",
              }),
              fill: new Fill({
                color: layer.colour || "rgba(51, 51, 51, 0.5)",
              }),
            }),
            text: new Text({
              text: size > 1 ? size.toString() : "",
              fill: new Fill({
                color: "#fff",
              }),
            }),
          });
          styleCache[styleKey] = style;
        }
        return style;
      };
    }
    var newLayer = new LayerType({
      visible:
        (collectionName &&
          typeof layer.layers === "string" &&
          layer.layers
            .toLowerCase()
            .replace(/_/g, "")
            .indexOf(collectionName.toLowerCase().replace(/_/g, "")) >= 0) ||
        false,
      source,
      ...params,
      // stow away some values for our own use only
      _fields: layer.fields,
      _query_rules: layer.query_fields ? getQueryRules(layer.query_fields) : [],
      _layer_name: layer.layers,
      _uppercase: layer.uppercase_field_names || false,
      _polygonal: layer.polygonal,
      _crumbs: crumbs,
      _long_name: [...crumbs, params.title].join(": "),
    });

    // Listens to https://openlayers.org/en/v5.3.0/apidoc/module-ol_layer_Vector-VectorLayer.html events.
    // Below is the check to apply load start/finish to other VectorLayers, if needed. Replace layer.title === "FosSahul Database" with it.
    // if (newLayer instanceof VectorLayer) {
    if (layer.title === "FosSahul Database" || layer.title === "ExpAge Database"  || layer.title === "Radiocarbon collection") {
      // Emitting event to show loader
      // Specifically on "making layer visible" changes.
      newLayer.on("change:visible", (_) => {
        if (newLayer.getVisible())
          document
            .querySelector("#loader")
            .dispatchEvent(new Event("loadstart"));
      });
      // Emitting event to stop showing the loader
      // NOT at featureloadend because after loading the datapoints, Vector layer still has to render it.
      newLayer.on("render", (_) => {
        document.querySelector("#loader").dispatchEvent(new Event("loadend"));
      });
    }

    return newLayer;
  }
  // else assume list:
  if (layer.disabled) {
    params.visible = false;
  }
  const fold = layer.open ? "open" : "close";
  const layers = layer.layers.map((l) =>
    buildLayers(l, [...crumbs, params.title])
  );
  // TODO: iterate layers for visible ones and auto-open
  return new Group({ fold, layers, ...params });
}

window.app = new Vue({
  el: "#app",
  components: {
    IconBase,
    IconChevronDown,
    IconChevronUp,
    LayerSwitcher,
    SidebarPane,
    VueQueryBuilder,
  },
  data() {
    const layerGroups = LAYER_HIERARCHY.map((l) => buildLayers(l));
    const filterQueries = {};
    const layersByKey = {};
    layerGroups.forEach((group) =>
      group.getLayersArray().forEach((layer) => {
        layersByKey[layer.get("_layer_name")] = layer;
        // Default filterQueries should not be empty object {}
        // Because filter config upload depends on it for default values,
        // Which in turn is used by vue-query-builder to autofill the rules.
        filterQueries[layer.get("_layer_name")] = {
          logicalOperator: "all",
          children: [],
        };
      })
    );
    return {
      showSplash: true,
      showLogo: false,
      caseSensitiveQueries: false,
      filterQueries,
      clusterDistance: INITIAL_CLUSTER_DISTANCE,
      clusteringEnabled: INITIAL_CLUSTERING_ENABLED,
      map: null,
      layerGroups,
      layersByKey,
      baseLayerGroup: BASE_MAPS_GROUP,
      downloadRequested: false,
      downloadFormData: {
        name: "",
        email: "",
        desc: "",
        zips: [],
      },
      exportFormData: {
        layerToExport: "",
        exportFormat: "shape-zip",
        exportIntendedUse: "",
      },
      publicationsAvailable: [],
      expandedLayer: null,
      // docs.geoserver.org/stable/en/user/services/wfs/outputformats.html
      vectorExportFormatOptions: [
        { text: "GML2", value: "GML2" },
        { text: "GML3", value: "GML3" },
        { text: "Shapefile", value: "shape-zip" },
        { text: "JSON", value: "application/json" },
      ],
      // docs.geoserver.org/latest/en/user/services/wms/outputformats.html
      imageExportFormatOptions: [
        { text: "KML", value: "kml" },
        { text: "KMZ", value: "kmz" },
      ],
      intendedUseOptions: INTENDED_USES,
      visibleFilters: false,
    };
  },
  computed: {
    visiblePublicationsAvailable() {
      const visibleCollectionNames = this.getNonBaseLayers(true).map((layer) =>
        layer
          .get("_layer_name")
          .split(":")[1]
          .split("_")
          .slice(0, -1)
          .join("_")
      );
      return this.publicationsAvailable.filter(
        (publication) =>
          visibleCollectionNames.indexOf(
            publication.split("/")[0].toLowerCase()
          ) >= 0
      );
    },
    downloadFormDataValid() {
      return (
        Object.values(this.downloadFormData).filter((v) => v.length === 0)
          .length === 0
      );
    },
    exportFormatOptions() {
      return [
        ...this.vectorExportFormatOptions,
        ...this.imageExportFormatOptions,
      ];
    },
  },
  watch: {
    filterQueries: {
      // This watches for changes in filterQueries, and as side effect, tells OpenLayers to redraw the current expanded layer.
      // Without this, the map doesn't redraw. It seems to have been limited to only the current visible layer than all to avoid
      // excessive rerenders due to the watcher.
      deep: true,
      handler() {
        if (this.expandedLayer) this.updateCQL(this.expandedLayer);
      },
    },
    clusteringEnabled(toggleValue) {
      if (!toggleValue) this.clusterDistance = 0;
      // set cluster distance to 0 to "toggle off"
      else this.clusterDistance = INITIAL_CLUSTER_DISTANCE; // toggling on resets it back to the default cluster distance.
    },
    clusterDistance(val) {
      this.map
        .getLayerGroup()
        .getLayersArray()
        .filter((layer) => layer instanceof VectorLayer)
        .forEach((layer) => layer.getSource().setDistance(val));
    },
  },
  mounted() {
    this.buildMap();
  },
  mixins: [validation],
  methods: {
    buildMap() {
      const container = document.getElementById("popup");
      const contentDiv = document.getElementById("popup-content");
      const closer = document.getElementById("popup-closer");
      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      closer.onclick = () => {
        overlay.setPosition(undefined);
        closer.blur();
        return false;
      };

      this.map = new Map({
        target: "map",
        overlays: [overlay],
        layers: [this.baseLayerGroup, ...this.layerGroups],
        view: new View({
          projection: PROJECTION,
          center: [14811355.844875183, -3060767.923623309],
          minZoom: 3,
          zoom: 4,
        }),
      });

      this.map.addControl(new ScaleLine());

      const app = this;
      const { map } = app;

      map.addControl(new Sidebar({ element: "sidebar", position: "left" }));
      // see https://openlayers.org/en/latest/examples/getfeatureinfo-image.html
      map.on("singleclick", (event) => {
        const viewResolution = map.getView().getResolution();
        contentDiv.innerHTML = "";
        overlay.setPosition(undefined);
        map.forEachLayerAtPixel(
          map.getEventPixel(event.originalEvent),
          (layer) => {
            if (layer.get("type") !== "base") {
              // ignore base layers
              const source = layer.getSource();
              const url = source.getGetFeatureInfoUrl(
                event.coordinate,
                viewResolution,
                PROJECTION,
                {
                  QUERY_LAYERS: layer.get("_layer_name"),
                  INFO_FORMAT: "application/json",
                  FEATURE_COUNT: WFS_FEATURE_COUNT,
                }
              );
              fetch(url)
                .then((response) => response.json())
                .then((json) => {
                  const thead = el("thead");
                  const tbody = el("tbody");
                  const table = el("table", { cellspacing: 0 }, [
                    el("caption", layer.get("title")),
                    thead,
                    tbody,
                  ]);
                  const getFields = (feature) =>
                    Object.keys(feature.properties).filter(
                      (property) =>
                        layer.get("_fields").indexOf(property.toLowerCase()) >=
                        0
                    );
                  json.features.forEach((feature, index) => {
                    if (index === 0) {
                      thead.appendChild(
                        el(
                          "tr",
                          getFields(feature).map((field) =>
                            el("th", formatFieldName(field.toLowerCase()))
                          )
                        )
                      );
                    }
                    tbody.appendChild(
                      el(
                        `tr.${index % 2 ? "odd" : "even"}`,
                        getFields(feature).map((field) =>
                          el(
                            "td",
                            formatFieldValue(
                              field.toLowerCase(),
                              feature.properties[
                                layer.get("_uppercase")
                                  ? field.toUpperCase()
                                  : field
                              ]
                            )
                          )
                        )
                      )
                    );
                  });
                  contentDiv.appendChild(table);
                  overlay.setPosition(event.coordinate);
                });
            }
          }
        );
      });

      // see https://openlayers.org/en/latest/examples/getfeatureinfo-image.html
      map.on("pointermove", (event) => {
        if (!event.dragging) {
          const pixel = map.getEventPixel(event.originalEvent);
          const hit = map.forEachLayerAtPixel(
            pixel,
            (layer) => layer.get("type") !== "base" // ignore base layers
          );
          map.getTargetElement().style.cursor = hit ? "pointer" : "";
        }
      });


      // https://openlayers.org/en/latest/examples/box-selection.html
      const dragBox = new DragBox({
        condition: platformModifierKeyOnly,
      });
      map.addInteraction(dragBox);
      // Listener click+drag to get the available zips for download selection.
      dragBox.on("boxend", () => {
        // Flush the previous checked selection with zip options refresh.
        this.downloadFormData.zips = [];
        const extent = dragBox.getGeometry().getExtent();
        fetch(`${CGI_URL}get-intersections.cgi?${extent}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            Vue.set(
              app,
              "publicationsAvailable",
              data.names
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()
            );
          });
      });

      window.map = map;
    },
    checkVisibleFilters() {
      const filterList = document.querySelectorAll(
        '.filter-layer:not([style*="display: none"])'
      );
      this.visibleFilters = filterList.length > 0;
    },
    getVisibleLayers() {
      if (!this.map) {
        return [];
      }
      const layers = this.map
        .getLayerGroup()
        .getLayersArray()
        .filter((layer) => layer.get("type") !== "base");
      const visibleLayers = layers.filter(
        // Comments from devikacreations handover
        // (layer) => layer.getVisible()
        // getVisible() doesn't respect parent
        // state_.visible seems reliable, but can't find an official
        // way of accessing it!
        // eslint-disable-next-line no-underscore-dangle
        (layer) => layer.state_ && layer.state_.visible
      );
      return visibleLayers;
    },

    getNonBaseLayers(visibleOnly) {
      if (!this.map) {
        return [];
      }
      if (visibleOnly) {
        return this.getVisibleLayers();
      }
      return [];
    },
    getExportLayerOptions() {
      return [
        {
          text: "-- select layer to export --",
          value: "",
        },
        ...this.getNonBaseLayers(true).map((layer) => ({
          text: layer.get("_long_name"),
          value: layer.get("_layer_name"),
        })),
      ];
    },
    getCQL(layerName) {
      const layer = this.layersByKey[layerName];
      const buildCQL = ({ type, query }) => {
        if (type === "query-builder-rule") {
          let { rule, selectedOperator: operator, value } = query;
          const ruleConfig = { ...QUERY_CONFIG[rule] };
          if (ruleConfig) {
            if (ruleConfig.type === "select") {
              // Comments from devikacreations handover
              operator = "="; // TODO: support LIKE for wildcard choices ?
              // change type locally to use sanitisation etc below
              if (typeof ruleConfig.choices[0].value === "string") {
                ruleConfig.type = "text";
              } else if (typeof ruleConfig.choices[0].value === "number") {
                ruleConfig.type = "numeric";
              }
            }
            if (layer.get("_uppercase")) {
              rule = rule.toUpperCase();
            }
            if (ruleConfig.type === "text") {
              if (value === null) {
                value = "";
              }
              // make case-insensitive
              if (!this.caseSensitiveQueries) {
                rule = `strToLowerCase(${rule})`;
                value = value.toLowerCase();
              }
              // escape special characters
              value = value.replace("'", "\\'");
              value = value.replace("%", "\\%");
              // insert wildcards
              if (operator === "contains") {
                operator = "LIKE";
                value = `%${value}%`;
              } else if (operator === "does not contain") {
                operator = "NOT LIKE";
                value = `%${value}%`;
              } else if (operator === "begins with") {
                operator = "LIKE";
                value = `${value}%`;
              } else if (operator === "ends with") {
                operator = "LIKE";
                value = `%${value}`;
              } else if (operator === "is empty") {
                operator = "=";
                value = "";
              } else if (operator === "is not empty") {
                operator = "!=";
                value = "";
              } else if (operator === "equals") {
                operator = "=";
              } else if (operator === "does not equal") {
                operator = "!=";
              }
              // single-quote string values
              value = `'${value}'`;
            } else if (ruleConfig.type === "numeric") {
              if (operator === "<>") {
                operator = "!=";
              }
            }
          }
          return `${rule} ${operator} ${value}`;
        }
        if (type === "query-builder-group") {
          // parenthesise subclauses if necessary
          return (query.children || [])
            .map(buildCQL)
            .map((clause) =>
              / (AND|OR) /.test(clause) ? `(${clause})` : clause
            )
            .join(query.logicalOperator === "all" ? " AND " : " OR ");
        }
        console.error(`Invalid type: ${type}`); // eslint-disable-line no-console
        return "";
      };
      return (
        buildCQL({
          type: "query-builder-group",
          query: this.filterQueries[layerName],
        }) || null
      );
    },
    updateCQL(layerName, customFilterQueries) {
      const cql = this.getCQL(layerName, customFilterQueries);
      const layer = this.layersByKey[layerName];
      const wmsSource = layer.get("wmsSource");
      if (wmsSource) {
        wmsSource.updateParams({ cql_filter: cql });
        layer
          .get("source")
          .getSource()
          .clear(); // reload clusters
      } else {
        layer.get("source").updateParams({ cql_filter: cql });
      }
    },
    showCopied(event) {
      event.target.classList.add("tooltipped-e");
    },
    hideCopied(event) {
      event.target.classList.remove("tooltipped-e");
    },
    clearFilter() {
      // This clears only the current active tab's query.
      const layer = this.layersByKey[this.expandedLayer];
      this.filterQueries[layer.get("_layer_name")] = {
        // This is to preselect the default 'all' operator as per https://github.com/dabernathy89/vue-query-builder.
        logicalOperator: "all",
        children: [], // This part is to clear the nested selections.
      };
    },
    clearAllFilters() {
      this.layerGroups.forEach((group) =>
        group.getLayersArray().forEach((layer) => {
          const layerName = layer.get("_layer_name");
          this.filterQueries[layerName] = {
            // This is to preselect the default 'all' operator as per https://github.com/dabernathy89/vue-query-builder.
            logicalOperator: "all",
            // This part is to clear the nested selections.
            children: [],
          };
          // updateCQL needs to be called to propagate changes affecting all map layers to OpenLayers
          // If we only set the value on Vue side, the map doesn't automatically update it
          // Doing it through Vue watcher will lead to over-rendering, so it's better to call it manually.
          this.updateCQL(layerName);
          // Make sure to call this to turn off loader. Workaround because OL 5.3.3 doesn't have "loadstart" event, and instead
          // the loader starts on "change" event.
          document.querySelector("#loader").dispatchEvent(new Event("loadend"));
        })
      );
    },
    downloadFilterConfig() {
      fileDownload(
        JSON.stringify(this.filterQueries),
        "octopus_filter_config.json"
      );
    },
    handleClearFilterConfigImport(e) {
      // Clears the input element so it's ready for the next import - a hack because
      // <input> onchange/oninput does not trigger with the same file name.
      e.target.value = "";
      this.clearAllFilters();
    },
    handleFilterConfigImport(e) {
      if (e.target.files.length !== 1) {
        return console.error("No file selected");
      } else {
        const reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onloadend = (event) => {
          // Callback for when the file load is done.
          const userConfigurations = JSON.parse(event.target.result);
          const [
            validNonEmptyConfigurationLabels,
            validConfigurations,
          ] = this.sanitizeFilterConfigurations(
            userConfigurations,
            this.filterQueries,
            e.target.files[0].name
          );
          if (validNonEmptyConfigurationLabels.length === 0) {
            const error =
              "Unable to load filter configurations. File is corrupted.";
            alert(error); // Give user some sort of indication.
            throw new Error(error); // Catch this to do something about it.
          } else {
            alert(`${e.target.files[0].name} is successfully loaded.`);
            // We prefill a lot of things on valid configuration import.

            // This should toggle on the data layers with presets for better UX.
            // Behaviour: toggle on the tiles with configurations only.
            // This part is responsible for updating the map, applying config.
            this.layerGroups.forEach((group) =>
              group.getLayersArray().forEach((layer) => {
                const currentLayerName = layer.get("_layer_name");
                if (
                  validNonEmptyConfigurationLabels.includes(currentLayerName)
                ) {
                  // If valid, we use the JSON data to preset it.
                  layer.setVisible(true);
                } else {
                  layer.setVisible(false);
                }
                this.filterQueries[currentLayerName] =
                  validConfigurations[currentLayerName];
                this.updateCQL(currentLayerName); // has to be called regardless - this update is for all layers.
                // Safeguard to turn off loader.
                document
                  .querySelector("#loader")
                  .dispatchEvent(new Event("loadend"));
              })
            );
          }
          // Toggle the visibleFilters
          this.visibleFilters = validNonEmptyConfigurationLabels.length > 0;
        };
      }
    },
    getExportURL(layerName) {
      const layer = this.layersByKey[layerName];
      const source = layer.get("wmsSource") || layer.get("source");

      const isVector =
        this.vectorExportFormatOptions.filter(
          (option) => option.value === this.exportFormData.exportFormat
        ).length > 0;
      const params = isVector
        ? {
            version: DEFAULT_WMS_VERSION,
            service: "WFS",
            request: "GetFeature",
            outputFormat: this.exportFormData.exportFormat,
            typeNames: layerName,
            srsName: PROJECTION,
          }
        : {
            layers: layerName,
            format: this.exportFormData.exportFormat,
            transparent: true,
            wms_version: DEFAULT_WMS_VERSION,
            request: "GetMap",
            styles: "",
            srs: PROJECTION,
            bbox: this.map
              .getView()
              .calculateExtent()
              .join(","),
            width: 1024,
            height: 1024,
          };
      const cql = source.getParams().cql_filter;
      if (cql) {
        params.cql_filter = cql;
      }
      return `${isVector ? WFS_URL : WMS_URL}?${stringify(params)}`;
    },
    exportLayer(layerName) {
      const link = el(
        "a",
        {
          href: this.getExportURL(layerName),
          target: "blank",
          download: layerName,
        },
        "Download Layer"
      );
      link.click(); // TODO: confirm Firefox allows unmounted click
    },
    requestExport() {
      // Placeholder payload data.
      // There's no real reason to use FormData but to follow what's in requestDowload, if the backend accepts other formats like JSON.
      // console.log("Requesting export data with metadata:", this.exportFormData);
      const formData = new FormData();
      formData.append("layer_name", this.exportFormData.layerToExport);
      formData.append("export_format", this.exportFormData.exportFormat);
      formData.append("intended_use", this.exportFormData.exportIntendedUse);
      // Use Vue two-way binding to update the exportFormData field values.
      // v-model="exportFormData.layerToExport" // in index.html to tell Vue which field goes where in JS.
      // Placeholder fetch. Change this to where the backend is tracking the export metadata.
      fetch(`${CGI_URL}track-export.cgi`, {
        method: "POST",
        body: formData,
      }).then((response) => {
        // eslint-disable-line no-unused-vars
        this.downloadRequested = true;
        this.publicationsAvailable = [];
      });
    },
    requestDownload() {
      const formData = new FormData();
      formData.append("name", this.downloadFormData.name);
      formData.append("email", this.downloadFormData.email);
      formData.append("desc", this.downloadFormData.desc);
      formData.append("zips", this.downloadFormData.zips);
      fetch(`${CGI_URL}request-download.cgi`, {
        method: "POST",
        body: formData,
      }).then((response) => {
        // eslint-disable-line no-unused-vars
        this.downloadRequested = true;
        this.publicationsAvailable = [];
      });
    },
  },

});
