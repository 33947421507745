import { Group, Tile } from 'ol/layer'
import { XYZ } from 'ol/source'

const APIKey = process.env.MAPTILER_API_KEY

export default new Group({
  title: 'Base maps',
  fold: 'close',
  layers: [
    new Tile({
      title: 'Maptiler Outdoor',
      type: 'base',
      visible: false,
      source: new XYZ({
        url: `https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=${APIKey}`,
        crossOrigin: 'anonymous',
        attributions: [
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 512,
      }),
    }),
    new Tile({
      title: 'Maptiler Positron',
      type: 'base',
      visible: false,
      source: new XYZ({
        url: `https://api.maptiler.com/maps/positron/{z}/{x}/{y}.png?key=${APIKey}`,
        crossOrigin: 'anonymous',
        attributions: [
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 512,
      }),
      
    }),
    new Tile({
      title: 'Maptiler Satellite Hybrid',
      type: 'base',
      visible: false,
      source: new XYZ({
        crossOrigin: 'anonymous',
        url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${APIKey}`,
        attributions: [
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 512,
      }),
    }),
    new Tile({
      title: 'Open Street Map',
      type: 'base',
      visible: false,
      source: new XYZ({
        crossOrigin: 'anonymous',
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attributions: [
          '©<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        ],
        tileSize: 256,
      }),
    }),
  ],
})
